<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false">
                <le-date-range ref="eventdate" label="操作时间" :minDate.sync="pageParam.params.startTime" :maxDate.sync="pageParam.params.endTime" />
                <!-- <le-input label="处理人" v-model="pageParam.params.phone" /> -->
            </le-search-form>
            <el-table :data="tabledata" max-height="300">
                <el-table-column label="内容" prop="content"></el-table-column>
                <el-table-column label="操作人" prop="operatorUserPhone">
                    <template slot-scope="{ row }">
                        <span>{{ row.operatorUserPhone?row.operatorUserPhone:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作时间" prop="createTimeText"></el-table-column>
            </el-table>
        </le-pagview>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                pageParam:{
                    url: this.$Config.apiUrl.getDeviceEvent,
                    method: "post",
                    params: {
                        startTime: '',
                        endTime: ''
                    },
                    freshCtrl: 1,
                },
                tabledata: [],
            }
        },
        props:['deviceCode'],
        created () {
        },
        methods:{
            handlerRest() {
                this.pageParam.params = {
                    startTime: '',
                    endTime: '',
                };
                this.$refs['eventdate'].value1 = ['','']
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                this.pageParam.freshCtrl++;
            },
            setTableData (datas) {
                this.tabledata = datas
            }
        }
    }
</script>