<template>
    <div>
        <le-pagview ref="eventlist" @setData="setTableData" :pageParam="pageParam" v-if="pageParam">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false">
                <le-input label="设备编号" v-model="pageParam.params.deviceCode" />
            </le-search-form>
            <el-table 
                ref="deviceStatusList"
                :data="tabledata" 
                :highlight-current-row="true" 
                max-height="300"
                style="width: 100%">
                <el-table-column prop="code" fixed="left" label="设备号" min-width="80">
                    <template slot-scope="{ row }">
                        <span>{{ row.code?row.code:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stationName" label="绑定站点" fixed="left" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.stationName?row.stationName:'-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    v-for="(item,index) in tableThead"
                    :key="index"
                    prop="companyName" 
                    :label="item" 
                    min-width="90" >
                    <template slot-scope="{ row }">
                        <span 
                            :class="setDurationClass(row.onlineState,item)" 
                            class="a-flex-1 a-flex-rcc">{{ row.onlineState | getDurationVal(item) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footerContent" slot-scope="scope" >
                <div class="a-flex-rfsc">
                    <div class="a-flex-rfsc">
                        <span class="a-c-master a-fw-b" style="margin-right: 40px">{{ scope.val.lastSevenOnline }}（过去7天有在线设备数）/{{ scope.val.totalDeviceNumber }}（总设备数）</span>
                    </div>
                </div>
            </template>
        </le-pagview>
    </div>
</template>

<script>
    let _this
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                utils: util,
                tableThead: [], // 表头
                pageParam: null,
                onlineColor: '',//控制某个时长显示对应颜色
                tabledata: [],
            }
        },
        props:['deviceCode','purchaseId'],
        filters:{
            initDic (value,dic) {
                if(value === '' || value === undefined || value === null){
                    return ''
                }else{
                    for(var i=0; i<dic.length;i++){
                        if(value==dic[i].value){
                            return dic[i].text
                        }
                    }
                }
            },
            getDurationVal (arr, text) {
                let duration = arr.find(res=>{
                    return res.countDate == text
                })
                if(duration){
                    if(duration.onlineTime && duration.onlineTime>=60){
                        return _this.utils.secondToHoursMinute(duration.onlineTime)
                    }else{
                        return '离线'
                    }
                }else{
                    return '离线'
                }
            },
            getOrderNumVal (row, text) {
                let arr = row.list
                let orderNum = arr.find(res=>{
                    return res.countDate == text
                })
                if(row.deviceType == 60){ // 门禁没有订单数
                    return ''
                }else if(orderNum){
                    if(orderNum.onlineTime && orderNum.onlineTime>=60) { 
                        return (orderNum.orderNum?orderNum.orderNum:0) + '单'
                    }else{
                        return ''
                    }
                    
                }else{
                    return ''
                }
            }
        }, 
        mounted () {
            this.pageParam = {
                url: this.$Config.apiUrl.purchaseChargerDevList,
                method: "post",
                params: {
                    id: this.purchaseId,
                    deviceCode: '',
                    startTime: this.$getDay.getTodayBeforeDays(7),
                    endTime: this.$getDay.getTodayBeforeDays(1)
                },
                freshCtrl: 1,
            }
            this.tableThead = this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).reverse()
            
            this.$getDic('onlineColor').then(res=>{ 
                this.onlineColor = res[0].text; 
                this.onlineColor=this.onlineColor*60*60 
            })
            _this = this
        },
        methods:{
            handlerRest() {
                this.pageParam.params = {
                    id: this.purchaseId,
                    deviceCode: '',
                    startTime: this.$getDay.getTodayBeforeDays(7),
                    endTime: this.$getDay.getTodayBeforeDays(1)
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs.eventlist.pageNum = 1
                this.pageParam.freshCtrl++;
            },
            setTableData (datas) {
                this.tabledata = datas
            },
            setDurationClass (arr, text) {
                let duration = arr.find(res=>{
                    return res.countDate == text
                })
                if(duration){
                    if(duration.onlineTime > this.onlineColor){
                        return 'status-green'
                    }else if(duration.onlineTime <= this.onlineColor && duration.onlineTime >= 60){
                        return 'status-yellow'
                    }else{
                        return 'status-gray'
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .status-green{
        background: #67C23ACC;
        min-width: 80px;
        color: #ffffff;
        font-size: 12px;
        border-radius: 3px;
        padding: 4px;
        letter-spacing: 1px;
    }
    .status-yellow{
        background: #E6A23C;
        min-width: 80px;
        color: #ffffff;
        font-size: 12px;
        border-radius: 3px;
        padding: 4px;
        letter-spacing: 1px;
    }
    .status-gray{
        background: #909399;
        min-width: 80px;
        color: #ffffff;
        font-size: 12px;
        border-radius: 3px;
        padding: 4px;
        text-align: center;
    }
</style>