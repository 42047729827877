<template>
    <div>
        <el-dialog
            title="发货"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="700px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="120px">
                <el-form-item prop="trackingNo" label="物流单号">
                    <el-input 
                        style="width:300px" 
                        v-model="form.trackingNo" 
                        @input="form.trackingNo=form.trackingNo.replace(/^\.+|[^\da-zA-Z]+/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="发货设备" prop="devFiles">
                    <!-- <el-button type="primary"  @click="checkDeviceCode">点击上传</el-button> -->
                    <multi-file-choose 
                        ref="uploadDev"
                        :accept="'.xls,.xlsx'" 
                        :readonlyName="'采购设备_'" 
                        placeholder="只能选择.xls, .xlsx文件" 
                        :taxFiles.sync='form.devFiles' :max="1"></multi-file-choose>
                </el-form-item>
            </el-form>
            <span v-if="errorDevs.length" class="tableTitle">异常设备号</span>
            <el-table 
                v-if="errorDevs.length"
                ref="goodsOrderList" 
                :data="errorDevs" 
                :highlight-current-row="true" 
                max-height="240"
                style="width: 100%">
                <el-table-column label="设备号" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.deviceCode || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="异常原因" min-width="120">
                    <template slot-scope="{ row }">
                        <span>{{ row.state | initDic(stateDic) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    import multiFileChoose from '../../components/formcy/multi-file-choose.vue';
    export default {
        components:{
            multiFileChoose
        },
        data() {
            return {
                dialogVisible: false,
                id: '',
                form: {
                    devFiles: '',
                    trackingNo: '',
                    deviceId: []
                },
                rules: {
					trackingNo: [{required: true, message:'请输入物流单号', trigger: 'blur'}],
					devFiles: [{required: true, message:'请上传设备号文件', trigger: 'change'}],
                },
                errorDevs: [],
                stateDic: [],
                devCategroyDic: [],
                devTypeDic: [],
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.form = {
                        devFiles: '',
                        trackingNo: '',
                        deviceId: []
                    },
                    this.$refs['uploadDev'].fileList = []
                    this.$refs['form'].resetFields()
                }
            },
            'form.devFiles': {
                deep: true,
                handler (val) {
                    if(val && val.length){
                        this.checkDeviceCode(val[0].url)
                    }
                }
            }
        },
        created () {
            // this.getDevCategroy()
            this.getDevType()
            this.$getDic('purchasePayType').then(res=>{ this.stateDic = res; })
        },
        methods:{
            // 获取设备种类
            getDevCategroy () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getDeviceCategroyList,
                    method: "post",
                    params: {}
                }).then(res => {
                    this.devCategroyDic = res.result.data.map(item=>{
                        return {
                            ...item,
                            label: item.deviceCategroy,
                            value: item.id
                        }
                    })
                })
            },
            // 获取设备型号
            getDevType () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getDeviceType,
                    method: "post",
                    params: {
                        deviceCategroy: ''
                    }
                }).then(res => {
                    this.devTypeDic = res.result.data.map(item=>{
                        return {
                            ...item,
                            label: item.name,
                            value: item.id
                        }
                    })
                })
            },
            checkDeviceCode (url) {
                if(!url){
                    this.$message.error('设备解析异常：未获取到设备文件地址')
                    return
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.checkDeviceCode,
                    method: "post",
                    extra: {
                        showErr: true,
                    },
                    params: {
                        url: url,
                        id: this.id
                    },
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.form.deviceId = res.result.data.deviceIds
                        this.form.errorDevs = res.result.data.results
                    } else {
                        this.$refs['uploadDev'].fileList = []
                    }
                })
                .catch(err=>{
                    try{
                        err = err.toString()
                        let deviceType = err.split('#')[1]
                        if(deviceType){
                            let deviceTypeText = this.devTypeDic.find(res=>{
                                return res.value == deviceType
                            }).label
                            let errText = err.replace(('#' + deviceType + '#'),deviceTypeText)
                            this.$message.error(errText)
                        }else{
                            this.$message.error(err.toString())
                        }
                        this.$refs['uploadDev'].fileList = []
                    }catch(er){
                        this.$refs['uploadDev'].fileList = []
                    }
                })
            },  
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('发货后将无法再修改采购单，是否确认发货？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            this.$Axios._post({
                                url: this.$Config.apiUrl.purchaseChargerSend,
                                method: "post",
                                params: {
                                    ...this.form,
                                    deviceUrl: this.form.devFiles[0].url,
                                    id: this.id
                                },
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$emit('success');
                                    this.dialogVisible = false
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
                
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
</style>