<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <div class="a-flex-rcc">
                <el-button type="primary" v-if="purchaseState == 1" @click="editType = 'edit'">编辑</el-button>
                <el-button type="primary" v-if="purchaseState == 1" @click="toDelivery">发货</el-button>
                <el-button type="primary" v-if="purchaseState == 2" @click="startPay">立即执行</el-button>
            </div>
        </div>
        <el-card class="content el-main">
            <el-form :model="form" ref="form" :rules="rules" label-width="80px" label-position="left" :disabled="editType == 'info'">
                <el-form-item label="商户" prop="companyId" label-width="60px">
                    <le-company-solo-select 
                        :canUse="false"
                        placeholder="请选择（可输入搜索）" 
                        v-model="form.companyId" 
                        style="padding: 0;width: 400px;" 
                        class="chooseCompany"></le-company-solo-select>
                </el-form-item>
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin: 36px 0 16px">
                    <span>设备信息</span>
                </div>
                <el-form-item label="" prop="purchaseDeviceList" label-width="0">
                    <!-- <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="addDev" v-if="editType != 'info'">添加采购设备</el-button> -->
                    <el-card shadow="hover" class="a-mt-20 " :body-style="{ padding: '10px 10px 0px' }" v-for="(item,index) in form.purchaseDeviceList" :key="index">
                        <div class="a-flex-rsbfs">
                            <div class="a-flex-1" >
                                <div class="a-flex-rfsc a-mb-10">
                                    <span>设备种类：</span>
                                    <el-select v-model="item.deviceCategory" placeholder="请选择设备种类" clearable style="width: 140px" @change="devCategroyChange(index)">
                                        <el-option v-for="item in devCategroyDic"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="a-plr-10">，设备类型：</span>
                                    <el-select v-model="item.deviceType" placeholder="请选择设备类型" clearable style="width: 140px">
                                        <el-option v-for="item in devTypeDic"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            :disabled="form.purchaseDeviceList[index].deviceCategory != item.categroyId">
                                        </el-option>
                                    </el-select>
                                    <span class="a-plr-10">，单价：</span>
                                    <jd-input-price v-model="item.amount" style="width:160px"></jd-input-price>
                                    <span class="">，</span>
                                    <span class="">购买数量：</span>
                                    <el-input style="width:100px" maxlength="6" v-model="item.number" @input="item.number=item.number.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                    <span>；</span>
                                </div>
                                <div class="a-flex-rfsc a-mb-10">
                                    <span style="padding-right: 10px">第</span>
                                    <el-input style="width:100px" maxlength="6" v-model="item.executePeriods" @input="item.executePeriods=item.executePeriods.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                    <span class="a-plr-10">年开始收取服务费，每台设备的收费周期为每</span>
                                    <el-input style="width:100px" maxlength="6" v-model="item.periods" @input="item.periods=item.periods.replace(/^(0+)|[^\d]+/g,'')"></el-input>
                                    <span class="a-plr-10">年收取</span>
                                    <jd-input-price v-model="item.serviceAmount" style="width:160px"></jd-input-price>
                                    <!-- <el-select v-model="item.executePeriods" placeholder="请选择扣费类型" clearable style="width: 140px">
                                        <el-option v-for="item in executePeriodsDic"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="a-plr-10">，服务费：</span>
                                    <jd-input-price v-model="item.serviceAmount" style="width:160px"></jd-input-price>
                                    <span class="a-plr-10">/台/年。</span> -->
                                </div>
                            </div>
                            <!-- <el-tooltip class="devList-btn" effect="dark" content="删除" placement="top" v-if="form.purchaseDeviceList.length > 1 && editType != 'info'">
                                <img src="../../assets/icon/option-del-circle.png" @click="deleteDev(index)" class="a-wh-16 a-mlr-12" />
                            </el-tooltip>
                            <el-tooltip class="devList-btn" effect="dark" content="添加" placement="top"  v-if="editType != 'info'">
                                <img src="../../assets/icon/option-add-circle.png" @click="addDev" class="a-wh-16 a-mlr-12" />
                            </el-tooltip> -->
                        </div>
                    </el-card>
                </el-form-item>
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin: 36px 0 16px">
                    <span>支付信息</span>
                </div>
                <el-form-item label="总金额">
                    <div class="a-flex-rfsc baseLine">
                        <span>￥</span>
                        <span class="a-fs-20">{{ totalAmount }}</span>
                    </div>
                </el-form-item>
                <el-form-item label="付款方式" prop="payType">
                    <el-select v-model="form.payType" placeholder="请选择" clearable style="width: 200px">
                        <el-option v-for="item in payTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="a-flex-rfsc a-mt-20" v-if="form.payType == 1">
                        <span>首付：</span>
                        <jd-input-price v-model="form.downPayment" style="width:200px"></jd-input-price>
                        <span class="a-plr-10">，每期金额：</span>
                        <jd-input-price v-model="currentAmount" style="width:200px" @blur="currentAmountInput"></jd-input-price>
                        <!-- <el-input style="width:200px" maxlength="6" v-model="form.totalTerm" @input="form.totalTerm=form.totalTerm.replace(/^(0+)|[^\d]+/g,'')"></el-input> -->
                        <span class="a-plr-10">，分期：{{ form.totalTerm }}</span>
                    </div>
                </el-form-item>
                <el-form-item label="领取方式" prop="receiveType">
                    <el-select v-model="form.receiveType" placeholder="请选择" clearable style="width: 200px">
                        <el-option v-for="item in receiveTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递地址" prop="provinceCode">
                    <le-input-district-choose ref="districtChoose" class="districtBox" style="padding-top: 0;padding-bottom: 0;"
                        :province.sync="form.provinceCode" 
                        :city.sync="form.cityCode" 
                        :area.sync="form.areaCode"
                        large></le-input-district-choose>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input style="width:400px" maxlength="50" v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item label="出库地" prop="originPlaceId">
                    <el-select v-model="form.originPlaceId" placeholder="请选择" clearable style="width: 200px">
                        <el-option v-for="item in originPlaceDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物流单号" prop="address" v-if="purchaseState > 1">
                    <span>{{ form.trackingNo?form.trackingNo:'' }}</span>
                </el-form-item>
                <el-form-item label="发货设备" prop="devUrl" v-if="purchaseState > 1">
                    <multi-file-choose 
                        ref="uploadFileDev"
                        :readonlyName="'采购设备表格_'" 
                        placeholder="只能上传.pdf文件" 
                        :taxFiles.sync='form.deviceUrl' 
                        :readonly="true"
                        :max="1">
                    </multi-file-choose>
                </el-form-item>
                <el-form-item label="采购合同" prop="incomeUrl">
                    <multi-file-choose 
                        ref="uploadFile"
                        :accept="'.pdf'" 
                        :readonlyName="'采购设备合同_'" 
                        placeholder="只能上传.pdf文件" 
                        :taxFiles.sync='form.incomeUrl' 
                        :readonly="editType=='info'"
                        :max="1">
                    </multi-file-choose>
                </el-form-item>
                <div style="height: 100px" v-if="purchaseState < 2"></div>
            </el-form>
            <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin: 16px 0 16px" v-if="purchaseState >= 2">
                <span>设备在线情况</span>
            </div>
            <dev-online v-if="purchaseState >= 2" :purchaseId="id"></dev-online>
            <div class="a-line-t-e0 footerBox" v-if="editType != 'info'">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <delivery ref="delivery" @success="deliverySuccess"></delivery>
    </div>
</template>

<script>
    import utils from '../../utils/util'
    import multiFileChoose from '../components/formcy/multi-file-choose.vue';
    import Delivery from './child/delivery.vue';
    import DevOnline from './child/devOnline.vue';
    import OperateLog from './child/operateLog.vue';
    export default {
        components:{
            multiFileChoose,
            OperateLog,
            DevOnline,
            Delivery
        },
        data() {
            var checkDev = async (rule, value, callback) => {
                let errorText = await this.checkDevParams(this.form.purchaseDeviceList)
                if(errorText){
                    callback(new Error(errorText))
                }else{
                    callback()
                }
            };
            var checkPayType = (rule, value, callback) => {
                if(this.form.payType == 1){
                    if(!this.form.downPayment){
                        callback(new Error('请输入首付金额'))
                    }else if(Number(this.form.downPayment) >= Number(this.totalAmount)){
                        callback(new Error('首付金额须小于总金额'))
                    }else if(!this.currentAmount){
                        callback(new Error('请输入每期金额'))
                    }else{
                        callback()
                    }
                }else{
                    callback()
                }
            };
            
            return {
                utils: utils,
                editType: '',
                tabs: '1',
                id: '',
                purchaseState: '',// 采购单状态
                form: {
                    companyId: '',
                    purchaseDeviceList: [{
                        deviceCategory: '',
                        deviceType: '',
                        amount: '',
                        number: '',
                        executePeriods: '', //周期扣费
                        serviceAmount: '',
                        periods: '',//每全年
                    }],
                    payType: '',//付款方式
                    downPayment: '',//首付金额
                    totalTerm: '',//分期期数
                    termType: 1,//分期期数单位 1--日，2--月，3--季，4--年
                    receiveType: '',//领取方式
                    provinceCode: '',
                    cityCode: '',
                    areaCode: '',
                    address: '',//详细地址
                    originPlace: '',// 出库地name，
                    originPlaceId: '',// 出库地id
                    incomeUrl: '',//合同地址
                },
                currentAmount: '',
                rules: {
                    companyId: [{required: true, message:'请选择采购商户', trigger: 'change'}],
                    purchaseDeviceList: [{ validator: checkDev, trigger: 'change' }],
                    payType: [{required: true, message:'请选择付款方式', trigger: 'change'},{ validator: checkPayType, trigger: 'change' }],//
                    receiveType: [{required: true, message:'请选择领取方式', trigger: 'change'}],//
                    provinceCode: [{required: true, message:'请选择省市区', trigger: 'change'}],
                    address: [{required: true, message:'请输入详细地址', trigger: 'blur'}],//详细地址
                    originPlaceId: [{required: true, message:'请选择出库地', trigger: 'change'}],// 出库地name，
                    incomeUrl: [{required: true, message:'请上传采购合同', trigger: 'change'}],//合同地址
                },
                devCategroyDic: [],
                devTypeDic: [],
                executePeriodsDic: [],
                payTypeDic: [],
                receiveTypeDic: [],//领取方式字典
                originPlaceDic: [],//出库地字典
            };
        },
        created () {
            this.id = this.$route.query.id?this.$route.query.id:''
            this.editType = this.$route.query.editType?this.$route.query.editType:'add'
            
            if(this.id) {
                this.getDetailsInfo()
            }
            this.getDevCategroy()
            this.getDevType()
            this.$getDic('executePeriods','select').then(res=>{ this.executePeriodsDic = res; })
            this.$getDic('purchasePayType','select').then(res=>{ this.payTypeDic = res; })
            this.$getDic('receiveType','select').then(res=>{ this.receiveTypeDic = res; })
            this.$getDic('originPlace','select').then(res=>{ this.originPlaceDic = res; })
        },
        computed: {
            // currentAmount () {
            //     if(this.totalAmount && (this.form.downPayment || this.form.downPayment === 0 || this.form.downPayment === '0') && this.form.totalTerm){
            //         return ((this.totalAmount - this.form.downPayment) / this.form.totalTerm).toFixed(2)
            //     }else{
            //         return '0.00'
            //     }
            // },
            totalAmount () {
                let amount = 0
                this.form.purchaseDeviceList.map(item=>{
                    let price = item.amount?Number(item.amount):0
                    let num = item.number?Number(item.number):0
                    amount += (price*num)
                })
                return amount.toFixed(2)
            }
        },
        mounted () {

        },
        methods: {
            // 切换选项卡
            handleTabsClick (tab) {
                this.tabs = tab.name
            },
            addDev () {
                this.form.purchaseDeviceList.push({
                    deviceCategory: '',
                    deviceType: '',
                    amount: '',
                    number: '',
                    executePeriods: '', //周期扣费
                    serviceAmount: '',
                    periods: '',//每全年
                })
            },
            deleteDev (index) {
                this.form.purchaseDeviceList.splice(index,1)
            },
            // 分期金额输入
            currentAmountInput () {
                let totalTerm
                if(this.totalAmount && (this.form.downPayment || this.form.downPayment === 0 || this.form.downPayment === '0') && this.currentAmount){
                    totalTerm = (this.totalAmount - Number(this.form.downPayment)) / Number(this.currentAmount)
                }else{
                    totalTerm = '0'
                }
                if(!Number.isInteger(totalTerm)) {
                    this.$message.error('每期金额不能整除，请重新输入')
                }else {
                    this.form.totalTerm = totalTerm
                }
            },
            getDevCategroy () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getDeviceCategroyList,
                    method: "post",
                    params: {}
                }).then(res => {
                    this.devCategroyDic = res.result.data.map(item=>{
                        return {
                            ...item,
                            label: item.deviceCategroy,
                            value: item.id
                        }
                    })
                })
            },
            getDevType () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getDeviceType,
                    method: "post",
                    params: {
                        deviceCategroy: ''
                    }
                }).then(res => {
                    this.devTypeDic = res.result.data.map(item=>{
                        return {
                            ...item,
                            label: item.name,
                            value: item.id
                        }
                    })
                })
            },
            devCategroyChange (index) {
                this.form.purchaseDeviceList[index].deviceType = ''
                this.form.purchaseDeviceList[index].amount = ''
                this.form.purchaseDeviceList[index].number = ''
                this.form.purchaseDeviceList[index].executePeriods = ''
                this.form.purchaseDeviceList[index].serviceAmount = ''
            },
            // 发货
            toDelivery () {
                this.$refs['delivery'].id = this.id
                this.$refs['delivery'].dialogVisible = true
            },
            // 发货成功
            deliverySuccess () {
                this.getDetailsInfo()
            },
            // 开始执行
            startPay () {
                this.$confirm('是否确认开始执行扣款计划？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.purchaseChargerExecute,
                        method: "post",
                        params: {
                            purchaseId: this.id
                        },
                    })
                    .then(res=>{
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.getDetailsInfo()
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                    .catch(err=>{

                    })
                }).catch(_=>{ })
            },
            // 获取详情
            getDetailsInfo () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.purchaseChargerDetail,
                    method: "post",
                    params: {
                        id: this.id
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        let resData = res.result.data
                        this.purchaseState = resData.purchaseState
                        if(resData.payType == 1) {
                            this.currentAmount = (((resData.totalAmount - resData.downPayment) / resData.totalTerm) / 100).toFixed(2)
                        }
                        this.form = {
                            ...resData,
                            downPayment: resData.downPayment?(resData.downPayment/100).toFixed(2):0,
                            payType: JSON.stringify(resData.payType),
                            receiveType: JSON.stringify(resData.receiveType),
                            originPlaceId: JSON.stringify(resData.originPlaceId),
                            incomeUrl: [{
                                name: '采购设备合同_' + resData.incomeUrl,
                                url: resData.incomeUrl
                            }],
                            deviceUrl: [{
                                name: '采购设备表格_' + resData.deviceUrl,
                                url: resData.deviceUrl
                            }]
                        }
                        this.form.downPayment = this.form.downPayment?Number(this.form.downPayment):this.form.downPayment.toString()
                        this.form.purchaseDeviceList = this.form.purchaseDeviceList.map(item=>{
                            return {
                                ...item,
                                amount: (item.amount/100).toFixed(2),
                                serviceAmount: (item.serviceAmount/100).toFixed(2),
                                executePeriods: JSON.stringify(item.executePeriods)
                            }
                        })
                        this.$refs['uploadFile'].fileList = this.form.incomeUrl
                        if(resData.areaCode){
                            this.$refs['districtChoose'].values = [resData.provinceCode, resData.cityCode, resData.areaCode]
                        }else if(resData.cityCode){
                            this.$refs['districtChoose'].values = [resData.provinceCode, resData.cityCode]
                        }else if(resData.provinceCode){
                            this.$refs['districtChoose'].values = [resData.provinceCode]
                        }
                        
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            },
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$confirm('是否确认提交？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(_=>{
                            let reqData = {
                                id: this.id,
                                ...this.form,
                                currentAmount: (this.currentAmount*100).toFixed(0),
                                originPlace: this.originPlaceDic.find(res=>{ return res.value == this.form.originPlaceId }).label,
                                downPayment: (this.form.downPayment*100).toFixed(0),
                                incomeUrl: this.form.incomeUrl[0].url,
                                provinceCode: this.form.provinceCode?this.form.provinceCode:'',
                                cityCode: this.form.cityCode?this.form.cityCode:'',
                                areaCode: this.form.areaCode?this.form.areaCode:'',
                            }
                            reqData.purchaseDeviceList = reqData.purchaseDeviceList.map(item=>{
                                return {
                                    ...item,
                                    amount: (item.amount*100).toFixed(0),
                                    serviceAmount: (item.serviceAmount*100).toFixed(0),
                                }
                            })
                            this.$Axios._post({
                                url: this.$Config.apiUrl.purchaseChargerAdd,
                                method: "post",
                                params: reqData
                            })
                            .then(res=>{
                                if (res.result.code == 0) {
                                    this.$message.success('操作成功')
                                    this.$router.back()
                                    this.$store.dispatch("delView", this.$route);
                                } else {
                                    this.$message.error(res.result.message)
                                }
                            })
                            .catch(err=>{

                            })
                        }).catch(_=>{ })
                    }
                })
            },
            cancelSubmit () {
                this.$confirm('是否确认返回？','温馨提示')
                .then(_=>{
                    this.$router.back()
                    this.$store.dispatch("delView", this.$route);
                })
                .catch(_=>{})
            },
            checkDevParams (datas) {
                return new Promise((resolve, reject) => {
                    if(datas.length && datas){
                        for (let index = 0; index < datas.length; index++) {
                            const item = datas[index];
                            if(!item.deviceCategory){
                                resolve('请选择设备种类')
                                return
                            }
                            if(!item.deviceType){
                                resolve('请选择设备类型')
                                return
                            }
                            if(!item.amount){
                                resolve('请输入设备单价')
                                return
                            }
                            if(!item.number){
                                resolve('请输入采购数量')
                                return
                            }
                            if(!item.executePeriods){
                                resolve('请输入开始收取服务费时间')
                                return
                            }
                            if(!item.periods){
                                resolve('请输入服务费收取周期')
                                return
                            }
                            if(!item.serviceAmount){
                                resolve('请输入服务费')
                                return
                            }
                        }
                        resolve()
                    }else{
                        resolve()
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .content{
        font-size: 14px;
        height: 100%;
        overflow-y: auto;
        .chooseCompany{
            /deep/ .el-input__inner{
                width: 400px !important;
            }
        }
        .s-btn-add{
            width: 135px;
        }
        .a-mb-10{
            margin-bottom: 10px;
        }
        .devList-btn{
            margin-top: 8px;
        }
        .baseLine{
            align-items: baseline;
        }
        .devicetabs{
            margin-top: 36px;
        }
        /deep/ .districtBox{
            &>div{
                margin: 0 !important;
            }
        }
        /deep/ .el-tabs__item {
            // color: #007aff;
            font-size: 14px;
        }

        /deep/ .el-tabs__item.is-active {
            color: #007AFF;
            font-weight: bold;
        }

        /deep/ .el-tabs__nav-wrap::after {
            background: none !important;
        }
    }
</style>